<template>
    <div class="d-flex flex-column flex-root">
        <div
            class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
            id="kt_login"
        >
            <!--begin::Aside-->
            <div
                class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15"
                :style="{ backgroundImage: `url(${backgroundImage})` }"
            >
                <!--begin: Aside Container -->
                <div class="d-flex flex-row-fluid flex-column justify-content-between">
                    <!--begin: Aside header -->
                    <a href="#" class="flex-column-auto">
                        <img src="media/logos/logo-letter-1.png" class="w-100" />
                    </a>
                    <!--end: Aside header -->
                    <!--begin: Aside content -->
                    <div
                        class="flex-column-fluid d-flex flex-column justify-content-center"
                    >
                        <h3 class="font-size-h1 mt-10 mb-5 text-white">
                            Benvenuti nella piattaforma di Tesseramento ACSI
                        </h3>
                        <p class="font-weight-lighter text-white opacity-80">
                            Semplificazione e innovazione al servizio delle associazioni
                        </p>
                    </div>
                    <!--end: Aside content -->
                    <!--begin: Aside footer for desktop -->
                    <div
                        class="d-none flex-column-auto d-lg-flex justify-content-between mt-15"
                    >
                        <div class="opacity-70 font-weight-bold text-white">
                            © 2021 - ACSI
                        </div>
                        <div class="d-flex">
                            <a href="media/privacy_policy.pdf" target="_blank" class="text-white">Privacy Policy</a>
                            <a v-if="false" href="#" class="text-white ml-10">Note Legali</a>
                            <a v-if="false" href="#" class="text-white ml-10">Contatti</a>
                        </div>
                    </div>
                    <!--end: Aside footer for desktop -->
                </div>
                <!--end: Aside Container -->
            </div>
            <!--begin::Aside-->

            <!--begin::Content-->
            <div style="width: 100%; max-width: 800px; margin: 0 auto;">
                <div
                    class="flex-row-fluid d-flex position-relative p-7 overflow-hidden"
                >
                    <div class="row" style="width: 100%; max-width: 800px; margin: 0 auto;">
                        <div class="col-12">
                            <div v-if="this.message && this.message_type">
                                <div class="text-center mb-10">
                                    <span v-if="valid"><i class="fas fa-check text-success font-size-h1"></i></span>
                                    <span v-if="!valid"><i class="fa fa-times text-danger font-size-h1"></i></span>
                                </div>

                                <div :class="'mb-10 font-size-h6 text-center alert alert-' + message_type">{{message}}</div>
                            </div>

                            <div v-if="certificate">
                                <div style="border: 2px solid red; padding: 10px;">
                                    <div class="row">
                                        <div class="col-md-5">CERTIFICATO</div>
                                        <div class="col-md-7"><strong>{{certificate.certificate.toUpperCase()}}</strong></div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5">SODALIZIO</div>
                                        <div class="col-md-7"><strong>{{certificate.sportingclub.toUpperCase()}}</strong></div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5">COMITATO</div>
                                        <div class="col-md-7"><strong>{{certificate.committee.toUpperCase()}}</strong></div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5">ANNO</div>
                                        <div class="col-md-7"><strong>{{certificate.sportsannualitydisplay.toUpperCase()}}</strong></div>
                                    </div>

                                    <div class="row" v-if="certificate.name">
                                        <div class="col-md-5">NOME</div>
                                        <div class="col-md-7"><strong>{{certificate.name.toUpperCase()}}</strong></div>
                                    </div>

                                    <div class="row" v-if="certificate.surname">
                                        <div class="col-md-5">COGNOME</div>
                                        <div class="col-md-7"><strong>{{certificate.surname.toUpperCase()}}</strong></div>
                                    </div>

                                    <div class="row" v-if="certificate.sportingclubroletype">
                                        <div class="col-md-5">QUALIFICA</div>
                                        <div class="col-md-7"><strong>{{certificate.sportingclubroletype.toUpperCase()}}</strong></div>
                                    </div>

                                    <div class="row" v-if="certificate.qualifica">
                                        <div class="col-md-5">RUOLO</div>
                                        <div class="col-md-7"><strong>{{certificate.qualifica.toUpperCase()}}</strong></div>
                                    </div>

                                    <div class="row" v-if="certificate.note">
                                        <div class="col-md-5">NOTE</div>
                                        <div class="col-md-7"><strong>{{certificate.note.toUpperCase()}}</strong></div>
                                    </div>

                                    <div class="row" v-if="certificate.certificatestartdate">
                                        <div class="col-md-5">DATA EMISSIONE</div>
                                        <div class="col-md-7"><strong>{{certificate.certificatestartdate}}</strong></div>
                                    </div>

                                    <div class="row" v-if="certificate.certificateenddate">
                                        <div class="col-md-5">DATA SCADENZA</div>
                                        <div class="col-md-7"><strong>{{certificate.certificateenddate}}</strong></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Content-->
        </div>
    </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
 import { mapState } from "vuex";
 import ApiService from "@/core/services/api.service";

 export default {
     name: "auth",

     data() {
         return {
             certificate: null,
             message: '',
             message_type: '',
             valid: 0,
         }
     },

     created() {
         this.certificate = null;
         this.message = '';
         this.message_type = '';
         this.valid = 0;

         let url = '/certificate/verify/' + this.$route.params.idcertificate;
         ApiService.query(url)
                   .then((response) => {
                       if (response.data.status === 'KO')
                       {
                           this.message = response.data.message;
                           this.message_type = 'danger';
                           this.valid = 0;

                           this.$bvToast.toast(response.data.message, {
                               title: response.data.message,
                               variant: 'danger',
                               solid: true
                           });
                       }

                       if (response.data.status === 'OK')
                       {
                           this.certificate = response.data.certificate;
                           this.message = response.data.message;
                           this.valid = response.data.valid;

                           if (response.data.valid)
                           {
                               this.message_type = 'success';

                               this.$bvToast.toast(response.data.message, {
                                   title: response.data.message,
                                   variant: 'success',
                                   solid: true
                               });
                           }
                           else
                           {
                               this.message_type = 'danger';

                               this.$bvToast.toast(response.data.message, {
                                   title: response.data.message,
                                   variant: 'danger',
                                   solid: true
                               });
                           }
                       }
                   })
                   .catch((error) => {
                       console.log(error);
                   })
     },

     methods: {
     },

     computed: {
         ...mapState({
             errors: state => state.auth.errors
         }),

         backgroundImage() {
             return process.env.BASE_URL + "media/bg/bg-4.jpg";
         }
     }
 };
</script>
